body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  width: 100vw;
}
h1 {
  font-size: 28px;
}
a {
  color: #495869;
}

a:hover {
  color: #697e96;
}

div > .hover:hover {
  color: #f2711c;
}

.white {
  color: #fff;
}

.orange {
  color: #f2711c;
}

.pulse {
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.fadeInFast {
  animation-duration: 1s;
  animation-name: fadeIn;
}

.fadeOutFast {
  animation-duration: 1s;
  animation-name: fadeOut;
}

.fadeInSlow {
  animation-duration: 2s;
  animation-name: fadeIn;
}

.fadeOutSlow {
  animation-duration: 2s;
  animation: fadeOut;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rotate {
  animation-duration: 2s;
  animation-name: rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: rotate(420deg);
    opacity: 0;
  }
}
